import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import NotificationAlert from "../../../components/notifications/notification-alert";
import InvestorRelationsNavData from "../../../data/investor-relations/investor-relations-nav-data";
import Navigation from "../../../components/navigation/navigation";
import Icon from "../../../components/custom-widgets/icon";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

const FinancialData = () => {
  const title = "Other Financial Data";
  const description =
    "At WaFd Bank, trust has always been the foundation of our relationship with customers; we respect your data";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/wfsl-financial-data/hero-financial-data-04282023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/about-us/investor-relations/wfsl-financial-data/hero-financial-data-04282023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/about-us/investor-relations/wfsl-financial-data/hero-financial-data-04282023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/about-us/investor-relations/wfsl-financial-data/hero-financial-data-04282023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/about-us/investor-relations/wfsl-financial-data/hero-financial-data-04282023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/about-us/investor-relations/wfsl-financial-data/hero-financial-data-04282023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/wfsl-financial-data/hero-financial-data-04282023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/investor-relations/wfsl-financial-data"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-financial-data-04282023.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "other-financial-data-hero",
    ...getHeroImgVariables(imgData),
    altText: "Businesswoman talking with client in office.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "A Look into Investing in WaFd Bank"
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      url: "/about-us/investor-relations",
      title: "Investor Relations"
    },
    {
      id: 3,
      active: true,
      title: "Other Financial Data"
    }
  ];

  const annualReportData = [
    {
      year: "2023",
      href: "/documents/wfsl-financial-data/wafd-bank-2023-annual-report.pdf"
    },
    {
      year: "2022",
      href: "/documents/wfsl-financial-data/wafd-bank-2022-annual-report.pdf"
    },
    {
      year: "2021",
      href: "/documents/wfsl-financial-data/wafd-bank-2021-annual-report.pdf"
    },
    {
      year: "2020",
      href: "/documents/wfsl-financial-data/wafd-bank-2020-annual-report.pdf"
    },
    {
      year: "2019",
      href: "/documents/wfsl-financial-data/wafd-bank-2019-annual-report.pdf"
    },
    {
      year: "2018",
      href: "/documents/wfsl-financial-data/wafd-bank-2018-annual-report.pdf"
    },
    {
      year: "2017",
      href: "/documents/wfsl-financial-data/wafd-bank-2017-annual-report.pdf"
    },
    {
      year: "2016",
      href: "/documents/wfsl-financial-data/wafd-bank-2016-annual-report.pdf"
    },
    {
      year: "2015",
      href: "/documents/wfsl-financial-data/wafd-bank-2015-annual-report.pdf"
    },
    {
      year: "2014",
      href: "/documents/wfsl-financial-data/wafd-bank-2014-annual-report.pdf"
    },
    {
      year: "2013",
      href: "/documents/wfsl-financial-data/wafd-bank-2013-annual-report.pdf"
    },
    {
      year: "2012",
      href: "/documents/wfsl-financial-data/wafd-bank-2012-annual-report.pdf"
    }
  ];

  const PdfIcon = () => <Icon name="file-pdf" class="mr-2 text-primary" />;
  const RightArrowIcon = () => <Icon name="arrow-right" class="mr-2 text-primary" />;
  const ExternalLinkIcon = () => <Icon name="external-link" class="mr-2 text-primary" />;

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <Navigation {...InvestorRelationsNavData} />
      <section className="container pb-0">
        <h1>Other Financial Data</h1>
        <h2 className="text-success">We value simplicity. And it shows in our numbers.</h2>
        <h4>
          In this section, you'll find links to additional financial data, including a history of dividends and splits,
          our current capital ratios, and something we like to call our "scorecard" which traces key financial metrics
          to when we first went public in 1982.
        </h4>

        <ul className="list-unstyled">
          <li>
            <a
              className="text-decoration-none"
              id="irs-form-9837-pdf"
              href="/documents/form-8937-lbc-merger.pdf"
              target="_blank"
              title="IRS Form 8937"
            >
              <PdfIcon />
              IRS Form 8937
            </a>
          </li>
          <li>
            <Link
              className="text-decoration-none"
              id="dividend-payment-history"
              to="/about-us/investor-relations/stock-dividend-history"
            >
              <RightArrowIcon />
              Dividend Payment History
            </Link>
          </li>
          <li>
            <ExternalLinkIcon />
            For historical price data on WaFd Bank stock going back ten years, please visit the{" "}
            <a
              className="text-decoration-none"
              id="nasdaq-national-market-website"
              rel="noopener noreferrer"
              href="http://www.nasdaq.com/symbol/wafd"
              target="_blank"
            >
              Nasdaq National Market website
            </a>
          </li>
          <li>
            <a
              className="text-decoration-none"
              id="current-capital-ratios-pdf"
              href="/documents/wafd-bank-capital-ratios.pdf"
              target="_blank"
              title="Current Capital Ratios"
            >
              <PdfIcon />
              Current Capital Ratios
            </a>
          </li>
          <li id="sec">
            <ExternalLinkIcon />
            For SEC Filings, please visit the{" "}
            <a
              className="text-decoration-none"
              id="sec-website"
              rel="noopener noreferrer"
              href="http://www.sec.gov/cgi-bin/browse-edgar?company=washington%20federal&amp;CIK=&amp;filenum=&amp;State=&amp;SIC=&amp;action=getcompany"
              target="_blank"
            >
              SEC website
            </a>
          </li>
          <li id="section-16-report">
            <a
              className="text-decoration-none"
              id="section-16-report-pdf"
              rel="noopener noreferrer"
              href=" https://www.sec.gov/cgi-bin/own-disp?action=getissuer&CIK=0000936528"
              target="_blank"
            >
              <ExternalLinkIcon />
              Section 16 Reports
            </a>
          </li>
          <li>
            <a
              className="text-decoration-none"
              id="scorecard-pdf"
              target="_blank"
              href="/documents/wfsl-financial-data/wafd-bank-scorecard-of-historical-financial-information.pdf"
              title='click here for our "scorecard" of historical financial information'
            >
              <PdfIcon />
              Historical Financial Information "Scorecard"
            </a>
          </li>
          <li>
            <RightArrowIcon />
            Our comprehensive fact sheet is now attached to our{" "}
            <Link className="text-decoration-none" id="financial-news" to="/about-us/investor-relations/financial-news">
              Earnings Release
            </Link>
          </li>
          <li>
            <ExternalLinkIcon />
            Our most recent{" "}
            <a
              className="text-decoration-none"
              id="proxy-statement"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.sec.gov/ixviewer/ix.html?doc=/Archives/edgar/data/0000936528/000093652823000219/wfsl-20231220.htm"
            >
              Proxy Filing
            </a>
          </li>
        </ul>
        <div id="annual-reports-data">
          <h2>Annual Reports</h2>
          <ul className="list-unstyled">
            {annualReportData.map((data, index) => (
              <li key={index}>
                <a
                  className="text-decoration-none"
                  id={`${data.year}-annual-report-pdf`}
                  href={data.href}
                  target="_blank"
                >
                  <PdfIcon />
                  {data.year} Annual Report
                </a>
              </li>
            ))}
          </ul>

          <NotificationAlert
            type="primary"
            id="investor-info-notification-alert"
            bodyText={`If you can't find what you're looking for here, please contact our Investor Relations team by emailing us at <a href="mailto:info@wafd.com">info@wafd.com</a>.<br/><br/>For more information about WaFd, Inc., our publicly traded holding company (Nasdaq: WAFD), or to request an investor kit, please <a href="mailto:info@wafd.com">email us</a> or call <a href="tel:206-626-8178">206-626-8178</a>`}
          />
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default FinancialData;
